<template>
  <div class="block-contacts" v-click-outside="close">
    <icon name="ic_support" class="block-contacts-icon" @click.native="toggle" />
    <slide-y-up-transition :duration="150">
      <div class="block-contacts-data" v-show="opened">
        <h3>Customer service</h3>
        <ul>
          <li><icon name="contact_email" /> <a :href="'mailto:' + settings.email_support">{{ settings.email_support }}</a></li>
        </ul>
      </div>
    </slide-y-up-transition>
  </div>
</template>

<script>
import Icon from "@/libs/Icon";
import {mapState} from "vuex";
import ClickOutside from 'vue-click-outside'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'Contacts',
  components: {
    Icon,
    SlideYUpTransition
  },
  computed: {
    ...mapState({
      user: state => state.user.data,
      settings: state => state.site.settings
    })
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      opened: false
    }
  },
  methods: {
    close () {
      this.opened = false
    },
    open () {
      this.opened = true
    },
    toggle () {
      this.opened = !this.opened
    }
  }
}
</script>

<style lang="less">
@black: #1C1C20;
@border-color: rgba(255, 255, 255, 0.3);

.block-contacts {
  margin-left: 10px;
  position: relative;

  &-icon {
    position: relative;
    top: 2px;
    cursor: pointer;
  }

  &-data {
    position: absolute;
    right: -26px;
    top: 45px;
    width: auto;
    min-width: 227px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: @black;
    z-index: 4;
    box-shadow: -4px -7px 13px 0 rgba(0, 0, 0, 0.15), 0 4px 9px 0 rgba(0, 0, 0, 0.5);

    transition: all 0.3s ease;
    padding: 20px;

    h3 {
      margin-bottom: 20px;
      color: #f7f7f7;
    }

    ul {
      font-size: 14px;

      li {
        margin-top: 10px;
        display: flex;
        align-items: center;

        *:first-child {
          margin-right: 10px;
        }
      }
    }

    &:before {
      position: absolute;
      content: ' ';

      width: 12px;
      height: 12px;

      transform: rotate(45deg);
      display: block;

      background: #19191C;
      border: 1px solid @border-color;
      z-index: 1;

      top: -7px;
      right: 28px;

      border-bottom: 1px solid transparent;
      border-right: 1px solid transparent;
    }
  }
}
</style>
