<template>
  <div class="menu" :class="{opened: opened}">
    <ul class="menu-list">
      <li class="menu-list-link title" @click="toggleClass()">
        <icon name="ic_menu_hide" />
        <span>Settings</span>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'PersonalData'}" class="menu-list-link" active-class="active">
          <icon name="ic_personal_data" />
          <span class="menu-list-item-extended">Personal Data</span>
        </router-link>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'Security'}" class="menu-list-link" active-class="active">
          <icon name="ic_security" />
          <span class="menu-list-item-extended">Security</span>
        </router-link>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'Notifications'}" class="menu-list-link" active-class="active">
          <icon name="ic_ntf" />
          <span class="menu-list-item-extended">Notifications</span>
        </router-link>
      </li>
      <li class="menu-list-item" v-if="isEditButtonDisplayed">
        <router-link :to="{name: 'AccountUsers'}" class="menu-list-link" active-class="active">
          <icon name="ic_users" />
          <span class="menu-list-item-extended">Account users</span>
        </router-link>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'DeleteData'}" class="menu-list-link" active-class="active">
          <icon name="ic_account" />
          <span class="menu-list-item-extended">Account closure</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '../libs/Icon'
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'RouterMenuSettings',
  components: {
    Icon
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      user: state => state.user.data,
      opened: state => state.menu.isMenuSettingsOpened
    }),
    isEditButtonDisplayed () {
      return this.permissions && this.permissions.canUserEdit
    }
  },
  methods: {
    /**
     * Change menu class
     *
     * @param event
     */
    toggleClass: function (event) {
      this.$store.dispatch('toggleSettingsMenu')
    }
  }
}
</script>
