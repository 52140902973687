<template>
  <div v-if="initialized" v-visibility-change="visibilityChange">
    <header class="header" ref="header">
      <div class="container header-inner">
        <div class="device-only">
          <div class="mobile-menu-burger">
            <div class="mobile-menu-burger-line" @click="openMenu" />
          </div>
          <div class="mobile-menu" :class="{'opened': menuShow}">
            <div class="menu-close" @click="closeMenu">
              <span class="menu-close-icon" />
            </div>
            <nav class="mobile-menu-list">
              <router-link :to="{name: 'Dashboard'}" class="mobile-menu-item" active-class="active">
                Dashboard
              </router-link>
              <router-link :to="{name: 'Wallets'}" class="mobile-menu-item" active-class="active">
                Wallets
              </router-link>
              <router-link :to="{name: 'Beneficiary'}" class="mobile-menu-item" active-class="active">
                Beneficiaries
              </router-link>
              <router-link :to="{name: 'Transactions'}" class="mobile-menu-item" active-class="active">
                Transactions
              </router-link>
              <router-link :to="{name: 'Logout'}" class="mobile-menu-item" active-class="active">
                Logout
              </router-link>
              <router-link :to="{name: 'Invest'}" class="mobile-menu-item" active-class="active">
                Investments
              </router-link>
            </nav>
          </div>
        </div>
        <router-link to="/" class="logo">
          <logo />
        </router-link>
        <div class="profile">
          <profile-menu />
          <notification-bell @click="toggleNotificationMenu" />
          <contacts />
        </div>
      </div>
    </header>

    <div class="page-wrapper">
      <div class="container page-content">
        <div>
          <router-menu />
        </div>
        <div class="bordered opened" v-if="$route.path.substring(0, 9) === '/settings'">
          <router-menu-settings />
        </div>

        <transition name="tab-change-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>

    <notification-modal :default-show.sync="notificationShow" @close="toggleNotificationMenu" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import RouterMenu from './../components/RouterMenu'
import RouterMenuSettings from './../components/RouterMenuSettings'
import NotificationBell from './../components/notifications/NotificationBell'
import NotificationModal from './../components/notifications/NotificationModal'
import ProfileMenu from "@/layouts/parts/ProfileMenu";
import Contacts from "@/libs/Contacts";
import ConnectMFA from '@/components/modals/ConnectMFA'
import Logo from "@/libs/Logo";

import { isEqualInt } from "@/libs/functions";
import debounce from 'lodash/debounce'

export default {
  name: 'App',
  components: {
    NotificationModal,
    NotificationBell,
    RouterMenu,
    RouterMenuSettings,
    ProfileMenu,
    Contacts,
    Logo
  },
  data () {
    return {
      notificationShow: false,
      menuShow: false,
      initialized: false
    }
  },
  computed: {
    ...mapGetters({
      isMfaEnabled: 'isMfaEnabled',
      isUser: 'isUser'
    }),
    ...mapState({
      user: state => state.user.data
    })
  },
  watch: {
    '$route' () {
      if (this.menuShow) {
        this.menuShow = false
      }

      this.$store.dispatch('wallet.reset')
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('getFeatures'),
      this.$store.dispatch('getUser'),
      this.$store.dispatch('getCompany'),
      this.$store.dispatch('getBalances'),
    ]).then(() => {
      this.initialized = true
      this.$centrifuge.connect()

      if (!this.isMfaEnabled && this.isUser) {
        this.$modal.show(ConnectMFA, {},{
          clickToClose: false
        })
      }
    })
  },
  beforeDestroy() {
    this.$centrifuge.disconnect()

    // Fix because centrifuge v3.1.0 don't reset token after disconnection
    this.$centrifuge._token = null
  },
  sockets: {
    'wallet.report' (data) {
      if (data.status === 'IN_PROCESS') {
        this.$notice.info('We started to prepare a report. You will receive a notification when the process is complete.', {
          timeout: 10000
        })
      } else if (data.status === 'ERROR') {
        this.$notice.error('Something was wrong. Please try again later', {
          timeout: 10000
        })
      } else if (data.status === 'SUCCESS') {
        this.$sdk.wallet.report.download(data.url)
      }
    },

    'createWallet' () {
      this.$store.dispatch('getBalances')
    },
    'updateUser' () {
      this.$store.dispatch('getUser')
    },

    'updateWallet' ({ id, amount, color, name, status, description, isVisible }) {
      this.$store.dispatch('updateBalance', {
        wallet_id: id,
        fields: {
          amount: amount,
          color: color,
          name: name,
          status: status,
          description: description,
          isVisible: isVisible
        }
      })
    },

    'createCard' ({ wallet_id }) {
      if (this.isSameWallet(wallet_id)) return

      this.$notice.success(`Check card order status in the <span class="noty_link">Wallet</span>.`, {
        timeout: 15000,
        callbacks: {
          onClick: debounce(() => {
            this.$router.push({ name: 'WalletView', params: { id: wallet_id } })
          }, 1000),
        },
      })
    },

    'createNotification' (data) {
      this.$store.commit('notification.create', data)
    },

    'updateNotification' (data) {
      this.$store.commit('notification.update', data)
    },

    'deleteNotification' ({ id }) {
      this.$store.commit('notification.delete', id)
    },

    'logoutUser' () {
      this.$store.dispatch('auth.logout')
    }
  },
  methods: {
    /**
     * Check if user is in the same wallet where he ordered a card
     */
    isSameWallet (id) {
      return this.$route.name === 'WalletCards' && isEqualInt(this.$route.params.id, id)
    },

    /**
     * Open mobile menu
     */
    openMenu () {
      this.menuShow = true
    },

    /**
     * Close mobile menu
     */
    closeMenu () {
      this.menuShow = false
    },

    /**
     * Display notification right bar
     */
    toggleNotificationMenu () {
      this.notificationShow = !this.notificationShow
    },

    visibilityChange () {
      this.$store.dispatch('getUser')
    }
  }
}
</script>
