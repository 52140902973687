<template>
  <fade-transition :duration="150">
    <div class="ntf-modal" v-show="displayed" v-body-scroll-lock="displayed">

      <transition name="slide">
        <div class="ntf-modal-bg" @click="close" />
      </transition>

      <transition name="slide">
        <div class="ntf-modal-menu">
          <!-- Search input-->
          <label for="ntf-modal-menu-search" class="ntf-modal-menu-wrap">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
              <g fill="none" fill-rule="evenodd">
                <polygon points="0 0 15 0 15 15 0 15" />
                <path fill="#19191C" d="M8.74921161,8.74924513 C7.70624898,9.79221295 6.00927687,9.79221295 4.96631423,8.74924513 C3.92357011,7.70627731 3.92335159,6.00929678 4.96631423,4.96632896 C6.00927687,3.92336114 7.70624898,3.92357966 8.74921161,4.96632896 C9.79217425,6.00929678 9.79217425,7.70627731 8.74921161,8.74924513 M10.2943172,3.42121573 C8.39937227,1.52626142 5.31615357,1.52626142 3.42120867,3.42121573 C1.52626378,5.31617004 1.52626378,8.39940405 3.42120867,10.2943584 C4.98794702,11.8611045 7.36624272,12.1309694 9.21442583,11.1072308 L10.7153918,12.6079857 C11.2378564,13.1306714 12.0854684,13.1306714 12.6081515,12.6079857 C13.1306162,12.0855185 13.1306162,11.2379023 12.6081515,10.715435 L11.1071856,9.21446166 C12.1309191,7.36626937 11.8610555,4.98796186 10.2943172,3.42121573" opacity=".2" />
              </g>
            </svg>
            <input id="ntf-modal-menu-search" class="ntf-modal-menu-wrap-search" type="text" placeholder="Search" v-model="search">
          </label>

          <!-- Notifications list-->
          <transition-group v-if="notifications" name="flip-list" tag="ul" class="ntf-modal-menu-list scrollbar-hide">
            <li v-for="notification of notifications" :key="notification.id">
              <div class="ntf-item" @click="menuItemClick(notification.id)"
                   :ref="`ntf_item_${notification.id}`"
                   :class="{
                   active: notification.id === main_item_id,
                   read: notification.isViewed,
                   required: notification.isRequired
                 }"
              >
                <div class="ntf-item-title" v-html="notification.title" />
                <div class="ntf-item-text" v-html="notification.text" />
                <div class="ntf-item-date">
                  {{ notification.createdAtMs | moment('DD-MM-YYYY') }}
                </div>
                <div class="ntf-item-image" v-if="notification.isViewed">
                  <img src="@/assets/images/icons/ic_mail_read.svg">
                </div>
                <div class="ntf-item-image" v-else>
                  <img src="@/assets/images/icons/ic_mail_unread.svg">
                </div>
              </div>
            </li>
          </transition-group>

          <!-- If list is empty -->
          <div v-if="!notifications" class="ntf-modal-menu-list-empty">
            Notification list is empty
          </div>
        </div>
      </transition>

      <transition name="slide">
        <div class="ntf-modal-window" v-if="mainItem">
          <div class="ntf-modal-close" @click="closeModal" v-if="mainItemCanBeClosed" />
          <div class="ntf-modal-window-wrap scrollbar-hide" style="overflow: scroll">
            <div class="ntfmw-title">
              <div class="ntfmw-title-text" v-html="mainItem.title" />
            </div>
            <div class="ntfmw-content-text" v-html="mainItem.text" />
            <div class="ntfmw-panel" v-cloak v-if="!mainItem.isViewed && mainItem.isRequired">
              <button class="ntfmw-panel-button default-button" @click="setAsViewed(mainItem.id, true)">
                Accept
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </fade-transition>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'

export default {
  name: 'NotificationModal',
  props: {
    defaultShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FadeTransition
  },
  data () {
    return {
      /**
       * Main dialog window
       */
      main_item_id: false,

      /**
       * Default show value
       */
      show: false,

      /**
       * searchInput
       */
      search: ''
    }
  },
  computed: {

    /** List of notifications */
    notifications: function () {
      const items = this.$store.state.notifications.items

      if (!this.search) {
        return items
      }

      return items.filter((item) => {
        const i1 = item.title.indexOf(this.search)
        const i2 = item.text.indexOf(this.search)
        return (i1 !== -1) || (i2 !== -1)
      })
    },

    /** Flag for changing mode of component */
    required: function () {
      if (this.$store.getters.getRequiredNotViewedNotifications > 0) {
        this.displayItemRequired()
        return true
      }
      return false
    },

    /** Check if modal must be forced displayed */
    displayed: function () {
      return this.defaultShow || this.show || this.required
    },

    mainItem: function () {
      if (this.main_item_id) {
        return this.notifications.find(item => item.id === this.main_item_id)
      }

      return null
    },

    mainItemCanBeClosed: function () {
      if (this.mainItem && this.mainItem.isRequired) {
        return this.mainItem.isViewed
      }

      return true
    }
  },
  created () {
    window.addEventListener('keyup', this.bindKeyDown)

    this.$store.dispatch('getNotifications').then(response => {
      this.load()
    })
  },
  methods: {
    close: function () {
      if (this.required || !this.displayed) {
        return
      }

      this.closeModal()
      this.show = false

      this.$emit('close')
    },
    open: function () {
      this.show = true
      this.closeModal()
    },

    /**
     * Close main Item
     */
    closeModal: function () {
      this.main_item_id = false
    },

    /**
     * After vue object will be created
     */
    load: function () {
      if (this.required) {
        this.open()
        this.displayItemRequired()
      }
    },

    /**
     * Close modal by pressing Esc button
     */
    bindKeyDown: function (event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },

    /**
     * Set item as viewed
     *
     * @param id
     * @param force
     */
    setAsViewed: function (id, force) {
      let mark = false
      const item = this.notifications.find(item => item.id === id)

      if (item.isViewed) {
        return
      }

      if (item.isRequired && force) {
        mark = true
      }

      if (!item.isRequired) {
        mark = true
      }

      if (mark) {
        this.$sdk.notifications.markAsRead(id).then(response => {
          this.$store.dispatch('getNotifications').then(() => {
            if (this.required) {
              this.displayItemRequired()
            }
          })
        })
      }
    },

    /**
     * Open modal window with item from notifications
     *
     * @param id
     */
    menuItemClick: function (id) {
      if (!this.mainItemCanBeClosed) {
        return
      }

      this.main_item_id = id
      this.setAsViewed(id, false)
    },

    /**
     * Display required item
     *
     * @return {boolean} - result of displaying
     */
    displayItemRequired: function () {
      for (const item of this.notifications) {
        if (item.isRequired && !item.isViewed) {
          this.menuItemClick(item.id)
          break
        }
      }
    }
  }
}
</script>

<style lang="less">
  @color-black: #19191c;
  @color-white: #fff;

  .ntf-modal {
    z-index: 100;

    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;

    &-menu {
      position: relative;
      z-index: 2;
      background: rgba(255, 255, 255, 1);
      width: 300px;
      height: 100%;
      padding: 0;
      overflow: hidden;

      color: @color-black;

      &-wrap {
        width: 100%;
        padding: 20px;
        z-index: 1;
        background: @color-white;
        display: block;

        &-search {
          width: 100%;
          border-radius: 6px;
          border: none;
          padding: 12px 15px 12px 37px;
          line-height: 16px;
          background: #f3f3f3;
          color: @color-black;
          font-size: 13px;
        }

        img,
        svg {
          position: absolute;
          left: 33px;
          top: 32px;
        }
      }

      &-list {
        height: calc(100% - 80px);
        position: relative;
        box-sizing: content-box;

        &-empty {
          padding: 70px 10px 20px;
          color: @color-black;
          font-size: 13px;
          text-align: center;
        }
      }
    }

    &-close {
      padding-top: 25px;
      text-align: center;
      opacity: .8;

      position: absolute;
      top: 40px;
      right: 40px;
      z-index: 2;

      cursor: pointer;
      color: @color-white;

      font-size: 10px;
      text-transform: uppercase;

      &:before,
      &:after {
        position: absolute;
        left: 9px; top: 0;

        width: 2px;
        height: 20px;

        background: @color-white;
        content: ' ';
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #0F0F1180;
    }

    &-window {
      position: absolute;
      background-color: @color-black;
      height: 100%;
      top: 0;
      margin-top: 0;
      padding: 0 0 0 300px;
      max-width: calc(820px + 300px);
      min-width: 320px;
      width: 100%;
      z-index: 1;

      color: @color-white;
    }

    &-window-wrap {
      position: relative;
      z-index: 1;
      height: calc(100% - 80px);
      box-sizing: content-box;

      padding: 47px 70px;
    }
  }

  .ntf-item {
    @color-border: rgba(25, 25, 28, 0.13);

    position: relative;
    padding: 20px 25px 20px 75px;
    border-bottom: 1px solid @color-border;
    cursor: pointer;
    background: rgba(25, 25, 28, 0.1);

    &.read {
      background: @color-white;
    }
    &.active {
      background: @color-black;
      margin: 0;

      .ntf-item {
        &-title,
        &-text,
        &-date {
          color: @color-white
        }
      }
    }
    &.required {
      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background: #EA4C17;
        border-bottom: 1px solid rgb(181, 61, 21);
      }
    }

    &-title {
      height: 16px;
      color: @color-black;
      margin-right: 75px;

      font-size: 13px;
      font-weight: 500;
      line-height: 16px;

      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;

      white-space: nowrap;
    }
    &-text {
      opacity: 0.6;
      color: @color-black;
      font-size: 12px;
      line-height: 1.4em;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      max-height: 31px;

      * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &-image {
      width: 41px;
      height: 41px;
      border-radius: 50%;
      background: @color-black;
      position: absolute;
      top: 50%;
      left: 20px;
      margin-top: -20px;

      img,
      svg {
        position: absolute;
        left: 11px;
        bottom: 10px;
      }
    }
    &-date {
      font-size: 12px;
      position: absolute;
      right: 25px;
      top: 22px;
      color: rgba(25,25,28,0.99);
    }

    .ntf-modal-menu-list li:first-child & {
      border-top: 1px solid #f7f7f7;
    }
  }

  .ntfmw {
    &-title {
      position: relative;

      &-image {
        position: absolute;
        left: 0;
        top: 0;
      }
      &-text {
        font-size: 50px;
        font-weight: bold;
        //padding-left: 70px;
        line-height: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-content {
      &-text {
        margin-top: 22px;
        line-height: 1.5em;

        img {
          max-width: 100%;
          margin: 20px 0 30px;
        }

        p {
          margin: 20px 0 10px;
        }

        ul {
          list-style-position: outside;
          list-style-type: disc;

          padding-left: 20px;

          li {
            margin: 10px 0 5px;
          }
        }

        u {
          text-decoration: underline;
        }

        strong,
        b {
          font-weight: 500;
        }

        table {
          width: 100%;
          border: 1px solid #39393C;

          tr {
            th {
              background: #ffffff14;
            }

            td,
            th {
              padding: 10px;
              border: 1px solid #39393C;
            }
          }
        }
      }
    }

    &-panel {
      padding-top: 25px;
      text-align: right;
    }
  }

  @media all and (max-width: 767px) {
    .ntf-modal {
      &-window {
        width: 100%;
        max-width: 100%;
        padding: 0;
        z-index: 3;

        &-wrap {
          padding: 47px;
        }
      }

      &-close {
        top: 15px;
        right: 30px;
      }
    }

    .ntfmw {
      &-title {
        &-text {
          font-size: 30px;
          //padding-left: 50px;
        }
        &-image {
          img {
            height: 36px;
          }
        }
      }
    }
  }

  /** Animation for open\close menu **/
  .slide-leave-active,
  .slide-enter-active {
    transition: .1s;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
</style>
