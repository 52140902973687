<template>
  <div class="menu desktop-only" :class="{'opened': opened}">
    <ul class="menu-list">
      <li class="menu-list-link title" @click="toggleClass()">
        <icon name="ic_menu_hide" />
        <span>Menu</span>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'Dashboard'}" class="menu-list-link" active-class="active">
          <icon name="ic_overview" />
          <span>Dashboard</span>
        </router-link>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'Wallets'}" class="menu-list-link" active-class="active">
          <icon name="ic_operations" />
          <span>Wallets</span>
        </router-link>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'Beneficiary'}" class="menu-list-link" active-class="active">
          <icon name="ic_user" />
          <span>Beneficiaries</span>
        </router-link>
      </li>
      <li class="menu-list-item">
        <router-link :to="{name: 'Transactions'}" class="menu-list-link" active-class="active">
          <icon name="ic_transactions" />
          <span>Transactions</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '../libs/Icon'
import {mapState} from "vuex";

export default {
  name: 'RouterMenu',
  components: {
    Icon
  },
  computed: {
    ...mapState({
      opened: state => state.menu.isMenuMainOpened
    })
  },
  methods: {
    toggleClass: function () {
      this.$store.dispatch('toggleMainMenu')
    }
  }
}
</script>
