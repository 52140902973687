<template>
  <div class="profile-menu" :class="{focused: isFocused}" v-click-outside="close">
    <div class="profile-menu-name text-right" @click="toggle">
      <p class="first-line desktop-only notranslate">{{ companyName }}</p>
      <p class="second-line desktop-only" v-if="userName">Logged in as <span class="notranslate">{{ userName }}</span></p>
      <icon name="ic_down" />
    </div>

    <slide-y-up-transition :duration="150">
      <ul class="profile-menu-items" v-show="isFocused">
        <router-link :to="{name: 'Settings'}" active-class="active" custom v-slot="{navigate}">
          <li @click="navigate">
            <icon name="ic_settings" />
            <span>Settings</span>
          </li>
        </router-link>
        <router-link :to="{name: 'Logout'}" custom v-slot="{navigate}">
          <li @click="navigate">
            <icon name="ic_logout"/>
            <span>Logout</span>
          </li>
        </router-link>
      </ul>
    </slide-y-up-transition>
  </div>
</template>

<script>
import Icon from '@/libs/Icon'
import {mapState} from "vuex";
import ClickOutside from 'vue-click-outside'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'ProfileMenu',
  components: {
    Icon,
    SlideYUpTransition
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      isFocused: false
    }
  },
  watch: {
    '$route' () {
      if (this.isFocused) {
        this.close()
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.data
    }),
    companyName: function () {
      return this.user ? this.user.profile.company : ''
    },
    userName: function () {
      return this.user ? this.user.profile.username : ''
    }
  },
  methods: {
    close () {
      this.isFocused = false
    },
    open () {
      this.isFocused = true
    },
    toggle () {
      this.isFocused = !this.isFocused
    }
  }
}
</script>
