<template>
  <div class="ntf-bell" @click.prevent="$emit('click')" :class="{'active': active}">
    <img src="@/assets/images/icons/ic_ntf_white.svg" alt="Notifications bell">
  </div>
</template>
<script>
export default {
  name: 'NotificationBell',
  computed: {
    active: function () {
      return this.$store.getters.getNotViewedNotificationsCount !== 0
    }
  }
}
</script>
<style lang="less">
  @color-black: #19191c;
  @color-white: #fff;

  .ntf-bell {
    color: @color-white;
    cursor: pointer;
    position: relative;

    &.active {
      &:before {
        content: '';
        display: block;
        background: red;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        position: absolute;
        border: 2px solid @color-black;
        box-sizing: border-box;
        top: 0; right: 0;
      }
    }
  }
</style>
