<template>
  <div v-cloak class="modal small" v-body-scroll-lock="true">
    <div class="modal-content">
      <div v-if="isMfaEnabled" @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content-section">
        <multi-factor-auth />
      </div>
    </div>
  </div>
</template>

<script>
import MultiFactorAuth from "@/components/settings/MultiFactorAuth";
import {mapGetters} from "vuex";
export default {
  name: 'ConnectMFA',
  components: {
    MultiFactorAuth
  },
  computed: {
    ...mapGetters(['isMfaEnabled'])
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
